import React, { useState } from 'react';
import { Form } from 'shared-ui/form';
import { InputField, Input } from 'bumbag/Input';
import { Button } from 'bumbag/Button';
import { Alert } from 'bumbag/Alert';
import { Columns } from 'bumbag/Columns';
import jsonp from 'jsonp';
import { useForm } from 'react-hook-form';
import { withResponsive } from 'shared-ui/src/contexts/responsive';
import FieldController from 'shared-bumbag/src/components/FieldController';

const SubscribeForm = ({responsive}) => {
  const form = useForm();
  const { errors, reset, setError, control } = form;
  const [loading, setLoading] = useState(false);
  const [globalError, setGlobalError] = useState('');
  const [status, setStatus] = useState('');

  const onSubmit = async (formData) => {
    const action = '//gmail.us20.list-manage.com/subscribe/post?u=f10c0f5ba231612b8d59aca1d&id=8771a42500';
    const uri = `EMAIL=${encodeURIComponent(formData.email)}`;
    const path = `${action}&${uri}`;
    const url = path.replace('/post?', '/post-json?');
    setLoading(true);
    sendData(url);
  };

  const sendData = url => {
    setStatus('sending');
    jsonp(url, { param: 'c' }, (err, data) => {
      setLoading(false);
      if (data.msg.includes('already subscribed')) {
        setStatus('duplicate');
      } else if (err) {
        setStatus('error');
        setError('email', {
          type: 'manual',
          message: 'Sorry, there has been an unknown error.'
        });
      } else if (data.result !== 'success') {
        setStatus('error');
        setError('email', {
          type: 'manual',
          message: 'Sorry, there has been an unknown error.'
        });
      } else {
        setStatus('success');
      }
    });
  };

  return (
    <Form form={form} onSubmit={onSubmit}>
      {globalError && <Alert type="error" message={globalError} closable onClose={() => setGlobalError(null)}/>}

      {(status !== 'success' && status !== 'duplicate') ? (
        <Columns>
          <Columns.Column spread={8} spreadDesktop={7} spreadTablet={6} spreadMobile={12}>

            <FieldController
              name="email"
              label="Email address"
              rules={{
                required: true,
                email: true
              }}
              render={(props) => (
                <Input
                  placeholder="Your email"
                  {...props}
                />
              )}
            />

          </Columns.Column>
          <Columns.Column alignY="bottom" spread={4} spreadDesktop={5} spreadTablet={6} spreadMobile={12}>
            <Button palette="primary" type="submit" isLoading={loading}>Subscribe for updates</Button>
          </Columns.Column>
        </Columns>
      ) : (
        <Alert type="success" title="Awesome!">{status === 'duplicate' ? 'You have already subscribed!' : 'Thanks for subscribing!'}</Alert>
      )}
    </Form>
  );
};

/*
<Controller
  control={control}
  name="email"
  render={({ onChange, onBlur, value, name }) => (
    <InputField
      placeholder="Email address"
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      label="Email address"
    />
  )}
/>
 */

export default withResponsive(SubscribeForm);
