export const isObject = item =>
  item && typeof item === 'object' && !Array.isArray(item);

export const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};
