import { isObjectEmpty, isObject } from 'shared-bumbag/src/utils/objects';
export const hasErrors = (errors) => (!isObjectEmpty(errors));
export const fieldErrorMessage = (errors, field) => (errors && errors[field] && errors[field].message) ? errors[field].message : null;

export const initializeBasicRules = (rules = {}, fieldLabel = 'field') => {
  const initRules = {};
  for (const [ruleKey, ruleValue] of Object.entries(rules)) {
    switch (ruleKey) {
      case 'email':
        initRules['pattern'] = {
          message: `Please enter a valid email`,
          ...ruleValue,
          value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        };
        break;

      case 'required':
        if(isObject(ruleValue)) {
          initRules[ruleKey] = ruleValue;
        } else {
          initRules[ruleKey] = {
            value: true,
            message: `${fieldLabel} is required`
          };
        }
        break;

      case 'minLength':
        if(isObject(ruleValue)) {
          initRules[ruleKey] = ruleValue;
        } else {
          initRules[ruleKey] = {
            value: ruleValue,
            message: `Please enter more than ${ruleValue} characters`
          };
        }
        break;

      default:
        initRules[ruleKey] = ruleValue;
    }

  }

  return initRules;
};
