import React, { useRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { InputField } from 'bumbag/Input';
import { FieldWrapper } from 'bumbag/FieldWrapper';
import { fieldErrorMessage, initializeBasicRules } from 'shared-bumbag/src/components/FieldController/utils';

const FieldController = ({children, rules, defaultValue, name, label, render, ...rest}) => {
  const inputRef = useRef();
  const { control, errors } = useFormContext();
  const error = fieldErrorMessage(errors, name);
  const basicRules = initializeBasicRules(rules, label);
  const isError = (error !== null && error !== undefined);

  const fieldWrapperProps = {};

  if(isError) {
    fieldWrapperProps.validationText = error;
    fieldWrapperProps.state = 'danger';
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={basicRules}
      onFocus={() => inputRef.current.focus()}
      render={({ onChange, onBlur, value}) => (
        <FieldWrapper
          label={label}
          {...fieldWrapperProps}
        >
          {render({onChange, onBlur, value, name, ref: inputRef})}
        </FieldWrapper>
      )}
    />
  );
};

FieldController.defaultProps = {
  defaultValue: '',
  hideLabel: false,
  rules: {}
};

export default FieldController;
